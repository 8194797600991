import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/prphairloss.jpg";



const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PRPhairBeardContPage = ({setIsModalOpen}) => (
  <>
  <Separator />
    {/* <!-- Content section 2 --> */}
    <Section bg="#f7f7fb" py={4}>
      <Container className="pt-3 pb-3">

          <Row className="pb-5">
              <Col>
                <Iwrap>


<Itext as="h2">PRP & <span>Exosomes</span></Itext>
</Iwrap>
      <SecondText>
      How PRP with Exosomes Treats Hair Loss

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img src={imgMobile} alt="prp beard restoration" className="rounded shadow" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>


                Hair loss can significantly impact one's self-image when facing the mirror. Fortunately, there are
                various surgical and non-surgical interventions available to address this issue. <br /> <br />

                Platelet-rich plasma (PRP) therapy stands out as an advanced treatment for hair restoration, offering a
                promising solution for those seeking non-surgical options to combat thinning hair. <br /> <br />

                Below is an overview of how PRP therapy addresses hair loss and essential information about
                platelet-rich plasma, including the role of exosomes: <br /> <br />

                Understanding PRP: A Brief Background <br /> <br />
                Initially developed in the 1970s to treat conditions like arthritis and muscle injuries, PRP therapy
                harnesses the body's natural healing mechanisms, finding applications across different medical
                fields. <br /> <br />

                Decoding Platelets and Plasma <br /> <br />
                Plasma, the fluid component of blood, primarily consists of water and proteins, facilitating the
                transportation of blood components. Platelets play a crucial role in healing processes, including
                clotting and stimulating hair follicles. <br /> <br />

                Insight into Exosomes <br /> <br />
                Exosomes play a pivotal role in tissue regeneration, containing numerous growth factors essential for
                healing. When used in PRP therapy, exosomes stimulate the production of new hair follicles, revitalizing
                dormant follicles that cause hair thinning. <br /> <br />

                Procedure Overview <br /> <br />
                PRP therapy involves isolating platelets from the patient's blood, processing them into an injectable
                form, and applying them to the affected area. <br /> <br />

                Comfort and Duration of Treatment <br /> <br />
                PRP injections typically cause minimal discomfort and take a short time to complete, offering a
                convenient option for non-surgical hair restoration. <br /> <br />

                Anticipating Results <br /> <br />
                Most individuals notice hair growth and thickening within a few months of PRP therapy, with optimal
                results achieved through a series of treatments over several months. <br /> <br />

                Longevity of Results <br /> <br />
                PRP treatments yield long-lasting outcomes, necessitating occasional touch-ups to maintain
                results. <br /> <br />

                Potential Side Effects <br /> <br />
                Although rare, potential side effects of PRP therapy include infection and discomfort at the injection
                site.. <br /> <br />
                Potential side effects include redness, soreness, swelling, bruising, discomfort of the injection sites
                and infection. <br /> <br />

                Eligibility and Considerations <br /> <br />
                PRP therapy is generally well-tolerated and suitable for individuals over 18 years old with good health.
                It can address various types of hair loss, Excluding medically related hair loss, due to underlying
                medical conditions.

              </Text>


              <br />
              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active">
                <Bookbutton>Book appointment</Bookbutton>
              </div>

            </Box>
          </Col>
        </Row>


      </Container>
    </Section>
    <SeparatorEnd />
  </>
);

export default PRPhairBeardContPage;
