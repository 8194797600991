import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import trust from "../../assets/image/png/trust.svg";
import imgMobile from "../../assets/image/jpeg/Save-face-excellence.png";



const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: #1a1a1a;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #1a1a1a;
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="Dermamina Awards" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
display:block;
margin-top:5px;
  box-shadow: none;
  border-radius: 3px;
  max-width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
 
  @media ${device.sm} {
    max-width: 30%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 40%;
  }
  @media ${device.xl} {
    
  }
`;

const SaveFacePage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="#FFEEDB" py={4}>
      <Container className="pt-lg-5 pb-lg-5 mt-lg-2 mb-lg-2">
        <Row>
            <Col lg="6" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false">
            <Iwrap>


<Itext as="h2"> Your safety Matters</Itext>
</Iwrap>
            </ Col>
        </Row>

          <Row >

          <Col lg="6" className="order-lg-2  mb-lg-0" data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false">
            <div
              className="pl-2"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="What is non surgical rhinoplasty" className="img-fluid" />
            </div>
          </Col>
              <Col  lg="6" className="mb-4 lg-pr-3 order-lg-1" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false">
           

      <Box className=" pt-lg-0">
          
          <Text fontSize="10px">
<br />
At Dermamina we have successfully attained the Save Face Accreditation, which signifies our consistent adherence to the highest industry standards. To earn this esteemed recognition, we underwent a rigorous evaluation conducted by an independent body. <br /> <br />

Save Face is a register accredited by the Professional Standards Authority, holding official recognition from the Government, the Department of Health, and NHS England. This acknowledgment underscores our unwavering commitment to delivering exceptional quality services, meeting the stringent criteria set by these esteemed organizations.







          </Text> 
       
        </Box>
              </Col>
         
     
         
        </Row>
      </Container>
    </Section>
  </>
);

export default SaveFacePage;








